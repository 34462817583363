import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import {Footer, ContactUsForm } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";


const ContactUs = ({ data }) => {
  const copy = useStaticQuery(graphql`
    query ContactUsQuery {
      myContactUs: airtable(data: {page_title: {eq: "Contact"}}) {
        data {
          header
          subheader
          subheader_2
          action
          Segment
          action_url
          alt_text
        }
      }
    }
  `);
  // console.log(data)
  return (
    <Layout
      pageTitle='Contact us'
    >
      <ContactUsForm 
        headingCopy={copy.myContactUs.data.header}
        subheadingCopy={copy.myContactUs.data.subheader}
        embedUrl={copy.myContactUs.data.action_url}
        embedTitle={copy.myContactUs.data.header}
      />
      {/* <Heading h4> { copy.myContactUs.data.subheader_2 } </Heading> */}
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query ContactUsPageContentQuery {
    
//   }
// `

export default ContactUs;